.mod-error-403,
.mod-error-404 {
  align-items: center;
  justify-content: center;

  h1 {
    display: inline-block;
    font-size: 50px;
    padding-right: 12px;
    animation: typing .5s alternate infinite;
  }
}