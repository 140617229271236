@import '/src/style/variables';
@import '/src/style/mixin';

.conversations {
  width: 360px;
  overflow: hidden;
  box-shadow: inset -1px 0 0 0 #e0e0e0;
}

.conversation {
  border-radius: 8px;
  transition: all 0.3s ease;
  &:hover, &.activate {
    background-color: #efefef;
  }
  &.unread {
    font-weight: bold;
    background-color: #efefef;
  }
}
