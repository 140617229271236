@import 'variables';

@mixin typo($font-size, $font-weight, $line-height) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin text-style($font-size, $font-weight, $line-height, $color) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transition-property($property...) {
  -webkit-transition-property: $property;
  -moz-transition-property: $property;
  -ms-transition-property: $property;
  -o-transition-property: $property;
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  -webkit-transition-property: $duration;
  -moz-transition-property:    $duration;
  -o-transition-property:      $duration;
  transition-property:         $duration;
}

@mixin transition-timing-function($timing...) {
  -webkit-transition-timing-function: $timing;
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  transition-timing-function:         $timing;
}

@mixin transition-delay($delay...) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin box-shadow($box-shadow...) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  -ms-box-shadow: $box-shadow;
  -o-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

@mixin box-sizing($box-sizing...) {
  -webkit-box-sizing: $box-sizing; /* Android ≤ 2.3, iOS ≤ 4 */
     -moz-box-sizing: $box-sizing; /* Firefox ≤ 28 */
          box-sizing: $box-sizing; /* Chrome, Firefox 29+, IE 8+, Opera, Safari 5.1 */
}

@mixin transform($transform...) {
  -webkit-transform: $transform; /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: $transform; /* IE 9 */
          transform: $transform; /* Firefox 16+, IE 10+, Opera */
}

@mixin linear-gradient($linear-gradient...) {
  background: -moz-linear-gradient($linear-gradient);
  background: -webkit-gradient($linear-gradient);
  background: -webkit-linear-gradient($linear-gradient);
  background: -o-linear-gradient($linear-gradient);
  background: -ms-linear-gradient($linear-gradient);
  background: linear-gradient($linear-gradient);
}

@mixin animation($animation...) {
  -webkit-animation: $animation; /* Chrome, Opera 15+, Safari 5+ */
          animation: $animation; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@mixin animation-name($animation-name...) {
  -webkit-animation-name: $animation-name;
          animation-name: $animation-name;
}

@mixin animation-delay($animation-delay...) {
  -webkit-animation-delay: $animation-delay;
          animation-delay: $animation-delay;
}

@mixin animation-duration($animation-duration...) {
  -webkit-animation-duration: $animation-duration;
          animation-duration: $animation-duration;
}

@mixin animation-iteration-count($animation-iteration-count...) {
  -webkit-animation-iteration-count: $animation-iteration-count;
          animation-iteration-count: $animation-iteration-count;
}

@mixin animation-timing-function($animation-timing-function...) {
  -webkit-animation-timing-function: $animation-timing-function;
          animation-timing-function: $animation-timing-function;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin aspect($width: 16, $height: 9) {
  aspect-ratio: $width / $height;

  @supports not (aspect-ratio: $width / $height) {
    &::before {
      content: '';
      float: left;
      padding-top: calc((#{$height} / #{$width}) * 100%);
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

@mixin mediaQuery($screenMin, $screenMax) {
  @media screen and (min-width: $screenMin) and (max-width: ($screenMax - 1)) {
    @content;
  }
}

@mixin mediaQueryMin($screen) {
  @media screen and (min-width: $screen) {
    @content;
  }
}

@mixin mediaQueryMinHeight($screen) {
  @media screen and (min-height: $screen) {
    @content;
  }
}

@mixin mediaQueryMax($screen) {
  @media screen and (max-width: ($screen - 1)) {
    @content;
  }
}

@mixin mediaQueryMaxHeight($screen) {
  @media screen and (max-height: ($screen)) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (max-width: ($screenMedium)) {
    @content;
  }
}

@mixin mobile() {
  @media screen and (max-width: ($screenSmall - 1)) {
    @content;
  }
}