@import '/src/style/mixin';

.blockStats {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 24px;
  background-color: #fff;
  @include box-shadow(0px 1px 2px 0 rgba(34, 36, 38, 0.15));
}

.snippetsSelector {
  @include box-shadow(0px 1px 2px 0 rgba(34, 36, 38, 0.15));

  width: 205px;
  height: 84px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
  cursor: pointer;
  color: #546e7a;
  font-weight: 600;

  &.active {
    border: 2px solid #1F70B5;
  }
}
.snippetContent {
  @include box-shadow(0px 1px 2px 0 rgba(34, 36, 38, 0.15));
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
  text-align: justify;

  [property="title"] {
    color: rgb(84, 110, 122);
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }

  [property="description"] {
    padding: 0.8rem;
    font-style: italic;
    border-left-style: solid;
    border-left-width: 2px;
    border-left-color: #057FBA;
  }

  [property="snippets"] {
    font-size: 1.1rem;

    ul {
      padding: 0;
      list-style: none;

      li {
        position: relative;
        padding-left: 16px;
        padding-bottom: 0.8rem;

        &::before {
          font-weight: 700;
          content: "○";
          position: absolute;
          left: -5px;
          color: #057FBA;
        }
      }
    }
  }
}