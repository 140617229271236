.modalFullscreen {
  :global {
    .ant-modal {
      top: 0;
      margin: unset;
      padding: unset;
      max-width: unset;
    }

    .ant-modal-centered::before {
      content: unset;
    }

    .ant-modal, .ant-modal-content {
      // width: 100vw !important;
      height: 100vh !important;
    }

    .ant-modal-content {
      display: flex;
      flex-direction: column;

      .ant-modal-body {
        flex: 1 1 auto;
        overflow: auto;
      }
    }
  }
}