@import '/src/style/variables';
@import '/src/style/mixin';

.avtContainer {
  position: relative;
  border-radius: 100%;
  background-color: #DADBE7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  text-transform: uppercase;
}

.iconChange {
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  cursor: pointer;
  background: var(--color-neutral-200);
  opacity: 0;
  transition: all 0.3s ease-in-out;

  svg {
    transform: rotate(45deg);
  }
}

.avtContainer {
  &:hover {
    .iconChange {
      opacity: .6;
    }
  }
}