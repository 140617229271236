@import '/src/style/mixin';

// CORE
.blueTxt {
  color: #fff;
  font-weight: bold;
  color: #057FBA;
}
.blueBg {
  color: #fff;
  font-weight: bold;
  background-color: #057FBA;
}
.orangeTxt {
  color: #fff;
  font-weight: bold;
  color: #ff6507;
}
.orangeBg {
  color: #fff;
  font-weight: bold;
  background-color: #ff6507;
}