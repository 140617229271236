#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.d-none {
  display: none;
}

/**
 * SECTION: cursor
 */

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.not-allow, .not-allowed {
  cursor: not-allowed;
}

/**
 * SECTION: text
 */

.oneline-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.twoline-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.threeline-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-capitalize, .capitalize {
  text-transform: capitalize;
}

.text-lowercase, .lowercase {
  text-transform: lowercase;
}

.text-uppercase, .uppercase {
  text-transform: uppercase;
}

/**
 * SECTION: color
 */

.black {
  color: $NeutralBlack;
}

.bg-black {
  background-color: $NeutralBlack;
}

.white {
  color: $NeutralWhite;
}

.bg-white {
  background-color: $NeutralWhite;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

/**
 * SECTION: position
 */
.relative {
  position: relative;
}