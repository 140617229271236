.filePreview {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 8px;
}

.previewContent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img, audio, video, object {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}