@import '/src/style/variables';

.list {
  .item {
    transition: all 0.3s;
    overflow: hidden;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;

    .meta {
      width: 100%;
    }

    .avatar {
      background: #fff;
      margin-top: 4px;
    }
    .iconElement {
      font-size: 32px;
    }

    &.read {
      opacity: 0.4;
    }
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      background: #e6f7ff;
    }
    .title {
      font-weight: normal;
      margin-bottom: 8px;
    }
    .description {
      font-size: 12px;
      line-height: 1.25rem;
    }
    .datetime {
      font-size: 12px;
      margin-top: 4px;
      line-height: 1.5rem;
    }
    .extra {
      float: right;
      color: black;
      font-weight: normal;
      margin-right: 0;
      margin-top: -1.5px;
    }
  }
}

.notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  img {
    display: inline-block;
    margin-bottom: 16px;
    height: 76px;
  }

  .text {
    color: #5c5c5c;
  }
}

.clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid black;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: black;
  }
}
