.FilePreview {
  display: flex;
  align-items: center;
  justify-content: center;
  // max-width: 50px;
  // max-height: 50px;
  * {
    max-width: 100%;
    max-height: 100%;
  }
}