.mediaItem {
  width: 150px;
  height: 250px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 1px #e8e8e8;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.isSelected {
  box-shadow: 0 0 4px 1px #002fff;
}