@import '/src/style/variables';
@import '/src/style/mixin';

.sectionLabel {
  font-size: 24px;
  font-weight: 700;
  color: $NeutralGray;
  margin-bottom: 0;
  line-height: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  position: relative;
}

.detailContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.videoContainer {
  @include aspect(240, 426);
  width: 240px;
  border-radius: 12px;
  overflow: hidden;
}

.noVideo {
  width: 100%;
  height: 100%;
  background-color: $ImgBgColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.jobContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  padding: 16px;
  border-radius: 12px;

}

.inputContainer {
  width: 400px;
}

.formBlock {
  padding: 16px;
  background-color: white;
  border-radius: 12px;
  margin-top: 16px;
}

.companyContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  padding: 16px;
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: white;
  border: 1px solid $LightGray;
  border-radius: 12px;
  z-index: 999;
  padding: 16px;
}