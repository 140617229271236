.control {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  transition: opacity .3s ease-in-out;
  display: flex;
  align-items: flex-end;
}

.controller {
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background: linear-gradient(180deg, rgb(24 25 32/ 10%) 2%, #181920 100%);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;

  >div {
    width: 40px;
  }
}