.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.toolbox {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;

  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
