.layout-sidebar {
  background: $PrimaryColor;

  &__logo {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $NeutralWhite;
    line-height: 1em;
    font-weight: bold;
    font-size: 1.5em;
    background-color: $SecondaryColor;
    white-space: nowrap;
  }

  &__menu-icon {
    width: 1em;
    height: 1em;
    margin-right: .5em;
  }

  &__menu {
    overflow: auto;
    max-height: calc(100vh - 70px);
    background-color: $PrimaryColor !important;

    & ul {
      background-color: $SecondaryColor !important;
    }

    &__item {
      &.ant-menu-item:hover,
      &.__active,
      &.__active:hover {
        background-color: $ActiveBgColor !important;
      }

      &.__active > span > a,
      &.__active > span > svg {
        color: $NeutralWhite !important;
      }
    }
  }
}
