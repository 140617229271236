@import '/src/style/variables';

.popover {
  width: 336px;
}

.bellIcon {
  cursor: pointer;
  color: $MidGrey !important;
  font-size: 18px;
  padding: 4px;

  :hover {
    color: $ActiveBlue !important;
  }
}
