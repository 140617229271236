@import '/src/style/variables';
@import '/src/style/mixin';

.messagesBox {
  overflow: hidden;
}

.message {
  margin: 0;
  padding: 8px 12px;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  max-width: 80%;
  word-break: break-word;
  text-overflow: ellipsis;
  border-radius: 18px;
  background-color: #efefef;
  &.ofOther {
    text-align: left;
    color: white;
    background-color: rgb(0, 132, 255);
  }

  p {
    margin: 0;
  }

  &.attachments {
    padding: 0;
    background-color: unset;
    border-radius: unset;
  }
}

.filePreview {
  position: relative;
  padding: 2px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  &:hover {
    .xIcon {
      opacity: 1;
    }
  }
}

.xIcon {
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
}