.mod-video-browse {
  flex-direction: column;

  .video-item {
    min-width: 310px;
    min-height: 600px;
    padding: 4px;
    position: relative;
    background: #fff;
    border: solid 3px purple;
    display: flex;
    flex: 0 0 31%;
    align-items: center;
    flex-direction: column;

    .ant-checkbox {
      transform: scale(1.1);
    }
  }
}