.imgPicker {
  position: relative;
  height: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 1px #e8e8e8;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  color: #fff;
  padding: 24px;
  text-shadow: #000 0px 0px 2px, #000 0px 0px 2px, #000 0px 0px 2px,
}