.mod-vibe-rating {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .vibe-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;

    overflow: auto;
    height: 100%;

    .video-item {
      min-width: 310px;
      min-height: 600px;
      padding: 4px;
      position: relative;
      background: #fff;
      border: solid 3px purple;
      display: flex;
      flex: 0 0 31%;
      align-items: center;
      flex-direction: column;
    }

    .btn-react {
      color: #3498db;
      background-color: white;
      border-color: #3498db;

      &.love {
        color: #e84393;
        border-color: #e84393;
      }

      &:hover {
        color: #fff;
        background-color: #3498db;
        border-color: #3498db;

        &.love {
          background-color: #e84393;
          border-color: #e84393;
        }
      }
    }
  }
}