@import '/src/style/variables';
@import '/src/style/mixin';

.messagesBox {
  overflow: hidden;
}

.message {
  margin: 0;
  padding: 8px 12px;
  display: inline-flex;
  flex-direction: column;
  max-width: 80%;
  word-break: break-word;
  text-overflow: ellipsis;

  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;

  background-color: #efefef;
  &.ofOther {
    text-align: left;
    color: white;
    background-color: rgb(0, 132, 255);
  }
}