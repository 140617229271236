.layout-header {
  height: 70px;
  min-height: 70px;
  background-color: $NeutralWhite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.1));

  &__toggle-icon {
    font-size: 1.25em;
    cursor: pointer;
  }

  &__user {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;

    &__image {
      width: 3em;
      height: 3em;
      border-radius: 50%;
      margin-right: .5em;
    }

    &__name {
      padding-right: 2em;
    }
  }
}
