@import '/src/style/mixin';

// AMB
.ambBar0,
.ambBar1,
.ambBar2,
.ambBar3,
.ambBar4,
.ambBar5,
.ambBar6 {
  height: 22px;
  position: relative;

  &::before, &::after {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }

  &::after {
    width: var(--score-value);
  }
}

.ambTxt0 {
  color: #057FBA;
}
.ambBar0 {
  &::before {
    opacity: 0.5;
  }

  &::before, &::after {
    background-color: #057FBA;
  }
}

.ambTxt1 {
  color: #f23109;
}
.ambBar1 {
  &::before {
    opacity: 0.5;
  }

  &::before, &::after {
    background-color: #f23109;
  }
}

.ambTxt2 {
  color: #ff6507;
}
.ambBar2 {
  &::before {
    opacity: 0.5;
  }

  &::before, &::after {
    background-color: #ff6507;
  }
}

.ambTxt3 {
  color: #057FBA;
}
.ambBar3 {
  &::before {
    opacity: .25;
  }

  &::before, &::after {
    background-color: #057FBA;
  }
}

.ambTxt4 {
  color: #f23109;
}
.ambBar4 {
  &::before {
    opacity: .25;
  }

  &::before, &::after {
    background-color: #f23109;
  }
}

.ambTxt5 {
  color: #ff6507;
}
.ambBar5 {
  &::before {
    opacity: .25;
  }

  &::before, &::after {
    background-color: #ff6507;
  }
}

.ambTxt6 {
  color: #057FBA;
  opacity: .75;
}
.ambBar6 {
  &::before {
    opacity: .25;
  }

  &::before, &::after {
    background-color: #057FBA;
  }
}
