@import '/src/style/mixin';

.videoPlayer {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 250px;
  height: 400px;
  min-width: 250px;
  max-width: 250px;
  min-height: 400px;
  max-height: 400px;
  cursor: pointer;
  background-color: black;
  color: white;

  // background: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), url('${props.image}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  // :hover {
  //   background: linear-gradient(0deg, rgb(0 0 0 / 20%), rgb(0 0 0 / 20%)), url('${props.animatedImage || ImageDefault}');
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center center;
  // }

  /** Video & Track Styles */
  video {
    width: 100%;
    height: 100%;
    // object-fit: cover;
  }

  video::cue {
    font-size: 16px;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }

  video::-webkit-media-text-track-display {
    @include transform(translateY(-10px));
    @include transition(transform .3s linear); // match with ./control/style.module.scss transition
  }

  &.showControls {
    video::-webkit-media-text-track-display {
      @include transform(translateY(-80px));
    }
  }

  // video::-webkit-media-text-track-display {
  //   overflow: visible !important;
  //   -webkit-box-sizing: border-box;
  //   background: black;
  //   padding: 8px;
  //   border-radius: 16px;
  // }

  // video::-webkit-media-text-track-container {
  //   overflow: visible !important;
  //   -webkit-transform: translateY(40%) !important;
  //   transform: translateY(40%) !important;
  //   position: relative;
  // }

  // video::-webkit-media-text-track-display-backdrop {
  //   background-color: black !important;
  //   overflow: visible !important;
  // }
}

.videoPlayerContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

.videoPlayerSpeaker {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 20px;
  right: 20px;
  // top: ${props.isLinkedin ? '78px' : '20px'};
}

.videoPlayerPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // display: ${!playing || playEnd ? 'flex' : 'none'};
}

.blackWallpaper {
  background: black;
}

.flip {
  video {
    transform: rotateY(180deg);
  }
}

// // track styles
// .chrome {
//   video::cue {
//     font-size: 24px;
//     opacity: 1;
//     background-color: black;
//     -webkit-transform: translateY(10%) !important;
//     transform: translateY(10%) !important;
//   }

//   video::-webkit-media-text-track-display {
//     overflow: visible !important;
//     -webkit-box-sizing: border-box;
//     background: black;
//     padding: 8px;
//     border-radius: 16px;
//   }

//   video::-webkit-media-text-track-container {
//     overflow: visible !important;
//     -webkit-transform: translateY(40%) !important;
//     transform: translateY(40%) !important;
//     position: relative;
//   }
// }

// .safari {
//   video::cue {
//     font-size: 24px;
//     opacity: 1;
//     background-color: black;
//   }

//   video::-webkit-media-text-track-display-backdrop {
//     background-color: black !important;
//     overflow: visible !important;
//   }

//   video::-webkit-media-text-track-display {
//     overflow: visible !important;
//     -webkit-box-sizing: border-box;
//   }

//   video::-webkit-media-text-track-container {
//     overflow: visible !important;
//     -webkit-transform: translateY(20%) !important;
//     transform: translateY(20%) !important;
//     position: absolute;
//   }
// }